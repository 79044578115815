import { withStyles } from '@material-ui/core/styles';
import React from 'react';

export interface Props {
  classes: {
    footer: string;
  };
}

const styles: object = () => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    width: '100%',
    textAlign: 'center',
  },
});

const Footer = ({ classes }: Props): React.ReactElement => (
  <footer className={classes.footer}>
    {`© ${new Date().getFullYear()} `}
    <a href="https://blog.chun.no">Super Train Ticket</a>
  </footer>
);

export default withStyles(styles)(Footer);
