import { connect } from 'react-redux';
import Layout from '../components/layout';
import * as loadingActions from '../actions/loadingActions';

export default connect(
  (state, props) => ({}),
  (dispatch: Function, props) => ({
    loadingStart: () => dispatch(loadingActions.loadingStart()),
    loadingEnd: () => dispatch(loadingActions.loadingEnd()),
    getUserStations: () => dispatch(stationActions.getUserStations()),
  })
)(Layout);
