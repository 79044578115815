import './layout.scss';

import React, { ReactElement } from 'react';

import LoadingView from '../containers/loadingContainer';
import Footer from './footer';
import Header from '../containers/headerContainer';

export interface Props {
  children: ReactElement | ReactElement[];
}

const Layout = ({ children }: Props): React.ReactElement => {
  let locale;
  if (typeof localStorage !== 'undefined') {
    locale = localStorage.getItem('super-train-ticket:locale');
    console.log(navigator.languages);
  }
  return (
    <>
      <Header />
      <LoadingView />
      <main className="main">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
