import { connect } from 'react-redux';
import LoginView from '../components/loginView';
import * as userActions from '../actions/userActions';

export default connect(
  (state, props) => ({}),
  (dispatch, props) => ({
    login: (email: string, password: string) => {
      dispatch(userActions.login(email, password));
    },
  })
)(LoginView);
