import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Color } from '../styles/styles';

export interface Props {
  classes: {
    loading: string;
    notLoading: string;
    progress: string;
  };
  isLoading: boolean;
}

const styles: object = () => ({
  loading: {
    visibility: 'visible',
  },
  notLoading: {
    visibility: 'hidden',
  },
  progress: {
    height: '0.5em',
    backgroundColor: Color.cyan700,
  },
});

const LoadingView = ({ classes, isLoading }: Props): React.ReactElement => (
  <div className={isLoading ? classes.loading : classes.notLoading}>
    <LinearProgress variant="query" className={classes.progress} />
  </div>
);

export default withStyles(styles)(LoadingView);
