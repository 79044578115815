import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export interface Props {
  classes: any;
  localeHeader: any;
}

const styles: object = () => ({
  menu: {
    height: '4em',
  },
});

const Header = ({ classes, localeHeader }: Props): React.ReactElement => (
  <AppBar className={classes.menu} position="static">
    <Toolbar>
      <div className={classes.left}>
        <Typography variant="h6" color="inherit" className={classes.logo}>
          {localeHeader.siteName}
        </Typography>
      </div>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(Header);
