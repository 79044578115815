import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';

export interface Props {
  classes: {
    loginContainer: string;
    loginForm: string;
    loginInput: string;
    loginButtonGroup: string;
    loginButton: string;
  };
  login: Function;
}

const styles: object = theme => {
  console.log(typeof theme);
  return {
    loginContainer: {
      height: 360,
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),' +
        '0px 4px 5px 0px rgba(0,0,0,0.14),' +
        '0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    loginForm: {
      display: 'flex',
      flexDirection: 'column',
    },
    loginInput: {
      marginTop: 48,
      backgroundColor: theme.palette.background.default,
      '&.Mui-focused': {
        color: theme.palette.secondary.dark,
      },
      '&.MuiFilledInput-underline::after': {
        borderBottom: `3px solid ${theme.palette.primary.light}`,
      },
    },
    loginButtonGroup: {
      display: 'flex',
      justifyContent: 'center',
    },
    loginButton: {
      marginTop: 48,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.down('sm')]: {
      loginContainer: {
        width: '100%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      loginContainer: {
        width: 800,
        margin: '0 auto',
        marginTop: '8em',
      },
    },
  };
};

const LoginView = ({ classes, login }: Props): React.ReactElement => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Container className={classes.loginContainer} maxWidth="sm">
      <form className={classes.loginForm}>
        <TextField
          id="email"
          label="Epost"
          className={classes.loginInput}
          type="text"
          autoComplete="current-password"
          margin="normal"
          variant="filled"
          value={email}
          onChange={(event): void => setEmail(event.target.value)}
        />
        <TextField
          id="password"
          label="Passord"
          className={classes.loginInput}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="filled"
          value={password}
          onChange={(event): void => setPassword(event.target.value)}
        />
        <div className={classes.loginButtonGroup}>
          <Button
            variant="contained"
            color="primary"
            className={classes.loginButton}
            onClick={(): void => login(email, password)}
          >
            Logg inn
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default withStyles(styles)(LoginView);
