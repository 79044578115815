import { connect } from 'react-redux';
import LoadingView from '../components/loadingView';

export default connect(
  (state: any, props) => {
    return {
      isLoading: state.loading.isLoading,
    };
  },
  (dispatch, props) => ({})
)(LoadingView);
